//function show and hide menu box - version desktop
let arrowDrop = document.getElementById("arrow-drop");
let menuBox = document.getElementById("menu-box");

arrowDrop.onclick = function showMenuBox() {
  if (menuBox.style.display === "none") {
    menuBox.style.display = "flex";
  } else {
    menuBox.style.display = "none";
  }
};
console.log("test");



let btnTop = document.getElementById("btnTop");
//  scroll to the top of the document
btnTop.onclick = function topFunction() {
  console.log("top");
  // document.document.body.scrollTop = 0; // For Safari
  // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};



// document.querySelectorAll(".contain-arrow .scrollup #btnTop").forEach((e,i)=>{
//     e.addEventListener("click",()=> {
//       console.log('top')
//        // e.parentNode.classList.
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth'
//           });

//     })
//   })


